import React from 'react'

import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { UIDropDownMenu, UILayout, UIText, UIIcon } from 'bora-material-ui'

import { required as validateRequired } from '../utils/validate'
import messages from '../consts/messages'

const xsStyles = {
  flagItem: { width: '30px', height: '20px' },
  ddSectionText: { padding: '10px 0 10px 29px' },
}

export const DefaultItemTemplate = ({ value } = {}) =>
  !value ? null : (
    <UILayout lineHeight="44px" justifyContent="center">
      <UIText color="#000000" align="left">
        {value.title || value}
      </UIText>
    </UILayout>
  )

DefaultItemTemplate.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const FlagItemTemplate = ({ value }) => (
  <UIIcon type={value.title || value} margin="8px" padding="0px" lineHeight="unset" xs={xsStyles.flagItem} />
)

FlagItemTemplate.propTypes = {
  value: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
}

export const DropDown = (props, { muiTheme = {} }) => {
  const {
    input: { value, name, onChange },
    itemsArr,
    required,
    itemTemplate,
    labelContainerProps,
    inputContainerProps,
    selectionTemplate,
    onlySelect,
    styles,
    xs,
    disabled,
    inputStyles,
    fullItemsList = [],
    locale,
  } = props

  const onlyOneItem = itemsArr.length === 1 && !onlySelect

  const select = (val, index) => {
    props.select(val, index)
    if (!onlySelect) onChange(index)
  }

  const initValue = value
  return (
    <UILayout column width="auto" padding="30px 30px 0 0" {...styles} xs={xs}>
      <UILayout {...labelContainerProps}>
        <UIText size="18px" color="#000000" weight="bold" align="left" translate={messages[name]} padding="0 0 2px 0" />
        <UIText
          text="*"
          size="18px"
          color="red"
          weight="bold"
          align="left"
          lineHeight="4px"
          whiteSpace="pre"
          display-if={required}
        />
      </UILayout>
      {/* <UIText
        size="14px"
        color="#000000"
        align="left"
        translate={messages[`${name}Description`]}
      /> */}
      <UILayout padding="16px 0 0 0" {...inputContainerProps} borderRadius="inherit">
        <UIDropDownMenu
          onlySelect={onlySelect}
          itemTemplate={itemTemplate}
          selectionRenderer={selectionTemplate || ((selectedValue) => selectedValue.title || selectedValue)}
          itemsArr={itemsArr}
          fullItemsList={fullItemsList}
          select={select}
          initValue={initValue}
          disabled={disabled}
          display-if={!onlyOneItem}
          inputStyles={inputStyles}
          locale={locale}
          {...muiTheme.ids.DropDownStyle}
          inputName={name}
        />
        <UILayout display-if={onlyOneItem} center height="41px" xs={xsStyles.ddSectionText}>
          <UIText size="18px" color="#000000" weight="bold" align="left" text={props.selectionRenderer(itemsArr[0])} />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

DropDown.propTypes = {
  itemsArr: PropTypes.array.isRequired,
  select: PropTypes.func.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  onlySelect: PropTypes.bool,
  labelContainerProps: PropTypes.object,
  inputContainerProps: PropTypes.object,
  styles: PropTypes.object,
  itemTemplate: PropTypes.any,
  selectionTemplate: PropTypes.any,
  selectionRenderer: PropTypes.func,
  xs: PropTypes.object,
}

DropDown.defaultProps = {
  disabled: false,
  required: false,
  onlySelect: false,
  select: () => {},
  itemTemplate: DefaultItemTemplate,
  selectionTemplate: DefaultItemTemplate,
  labelContainerProps: {},
  inputContainerProps: {},
  styles: {},
  selectionRenderer: () => {},
  xs: {},
}

DropDown.contextTypes = {
  muiTheme: PropTypes.any,
}

const DropDownField = ({ name, label, validate = [], required, placeholder, ...props }) => {
  const fieldProps = { required }
  const validateFunc = required ? [validateRequired, ...validate] : validate

  return (
    <Field
      name={name}
      type="text"
      label={label}
      component={DropDown}
      props={fieldProps}
      placeholder={placeholder}
      validate={validateFunc}
      {...props}
    />
  )
}

DropDownField.defaultProps = {
  name: '',
  placeholder: '',
}

DropDownField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export default DropDownField
