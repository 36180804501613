export const SHOW_NOTIFICATION = 'bora/SHOW_NOTIFICATION'
export const REMOVE_NOTIFICATION = 'bora/REMOVE_NOTIFICATION'
export const TOGGLE_DIRECTION = 'bora/TOGGLE_DIRECTION'
export const SET_RESERVATION_CUSTOMER_INFO = 'bora/SET_RESERVATION_CUSTOMER_INFO'
export const SET_RESERVATION_PASSENGER_INFO = 'bora/SET_RESERVATION_PASSENGER_INFO'
export const ADD_RETURN_ROUTE = 'bora/ADD_RETURN_ROUTE'

export const FETCHING_START = 'network/FETCHING_START'
export const FETCHING_COMPLETE = 'network/FETCHING_COMPLETE'
export const SET_NETWORK_ERROR = 'network/SET_NETWORK_ERROR'
export const CLEAR_NETWORK_ERROR = 'network/CLEAR_NETWORK_ERROR'

export const FETCH_DATA_FOR_RESERVATION_UPDATE = 'bora/FETCH_DATA_FOR_RESERVATION_UPDATE'

export const GO_TO_PAGE = 'navigate/GO_TO_PAGE'
export const GO_TO_REFUND = 'navigate/GO_TO_REFUND'
export const GO_TO_EDIT_RESERVATION = 'navigate/GO_TO_EDIT_RESERVATION'
export const BACK_TO_PREVIOUS_PAGE = 'navigate/BACK_TO_PREVIOUS_PAGE'

export const SHOW_MODAL = 'SHOW_MODAL'
export const SET_SELECTED_SAIL_PACKAGE_INDEX = 'SET_SELECTED_SAIL_PACKAGE_INDEX'
export const RESET_SELECTED_SAIL_PACKAGE_INDEX = 'RESET_SELECTED_SAIL_PACKAGE_INDEX'
export const SET_MODAL_DATA = 'SET_MODAL_DATA'
export const CLEAR_MODAL_DATA = 'CLEAR_MODAL_DATA'
export const UPDATE_MODAL_STATE = 'UPDATE_MODAL_STATE'

export const SWITCH_VIEW_MODE = 'SWITCH_VIEW_MODE'

export const ACTION_WITH_COMMON_USER = 'ACTION_WITH_COMMON_USER'

export const USER_NEED_TO_PAY = 'USER_NEED_TO_PAY'

export const SET_VIEW_MODE = 'SET_VIEW_MODE'

export const SHOW_MESSAGE = 'SHOW_MESSAGE'

export const UPDATE_EDITABLE_FIELDS = 'UPDATE_EDITABLE_FIELDS'

export const SET_PASSENGER_TO_EDIT = 'SET_PASSENGER_TO_EDIT'
export const SET_RESERVATION_DRIVER_DETAILS = 'bora/SET_RESERVATION_DRIVER_DETAILS'
