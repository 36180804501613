import React, { Component, Fragment } from 'react'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import s from './SentryCatcher.module.scss'
import { isReallyProduction } from './utils/formFillinUtils'

const FullScreenWrap = styled.div`
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`

// const FlatButton = styled.button`
//   font-size: 2rem;
//   padding: 0.3rem;
// `

export class SentryCatcher extends Component {
  state = { error: null }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
      Sentry.captureException(error)
    })
  }

  render() {
    if (this.state.error) {
      const { message, stack } = this.state.error
      return (
        <FullScreenWrap>
          <div className={s.wrapper}>
            <pre>Critical application error: </pre>
            <pre className={s.message}>{message}</pre>
            {isReallyProduction() && (
              <details className={s.stack}>
                <summary>See the stack</summary>
                <pre className={s.stackContent}>{stack}</pre>
              </details>
            )}
          </div>
        </FullScreenWrap>
      )
    }
    return <Fragment>{this.props.children}</Fragment>
  }
}
