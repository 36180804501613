import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIIcon } from 'bora-material-ui'
import { getMaritimeStyle } from '../../utils/maritimeStyleUtils'

export const IconBeforeField = ({ icon, showPlus, plusColor = '#dedede' }, { muiTheme }) => (
  <Fragment display-if={!muiTheme.ids.DisableIconInputField}>
    <UILayout
      display-if={icon}
      zIndex={1}
      top="50%"
      left="10px"
      width="22px"
      height="auto"
      shrink="0"
      style={{
        marginInlineEnd: '0.75rem',
      }}
    >
      <UILayout center j-flex-center width="22px">
        <UIIcon type={icon} fill="#000000" left="0px" />
      </UILayout>
    </UILayout>

    <UIText display-if={showPlus} color={plusColor} margin={getMaritimeStyle('0', '0 8px 0 0')}>
      +
    </UIText>
  </Fragment>
)

IconBeforeField.propTypes = {
  icon: PropTypes.string.isRequired,
  plusColor: PropTypes.string.isRequired,
  showPlus: PropTypes.bool.isRequired,
}

IconBeforeField.contextTypes = {
  muiTheme: PropTypes.any,
}
