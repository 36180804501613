import React, { useEffect, useReducer, useRef } from 'react'
import * as R from 'ramda'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import useSWR from 'swr'
import axios from 'axios'
import ContentEditable from 'react-contenteditable'
import moduleStyles from './Translations.module.scss'

const getActualKey = R.compose(R.last, R.split('.'))

const KeyPrefix = styled.span`
  opacity: 0.4;
  color: #3b3b3b;
`
const Row = styled.div`
  margin: 0 auto;
  display: flex;
  width: 85%;
  justify-content: space-between;
  padding: 3px 0;
`
const Heading = styled.strong`
  flex: 1 1 400px;
  text-align: end;
  padding-right: 20px;
  font-weight: 800;
  font-family: monospace;
`

const AroundValue = styled.div`
  width: 100%;
`

const AroundDownloadButton = styled.a`
  display: flex;
  width: 100%;
  padding: 15px;
  justify-content: center;
  margin-bottom: 2rem;
`

const initialState = {
  translations: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'updateValue':
      return { ...state, translations: { ...state.translations, [action.payload.key]: action.payload.value } }
    case 'setValues':
      return { translations: action.payload }
    default:
      throw new Error()
  }
}

const Translations = (props) => {
  const {
    match: {
      params: { locale },
    },
  } = props

  const { data } = useSWR(`/info/translate/${locale}`, () => axios(`info/translate/${locale}`))

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    if (data && data[locale]) {
      dispatch({ type: 'setValues', payload: data[locale] })
    }
  }, [data, locale])

  const currentText = useRef('')

  const handleChange = (event) => {
    const {
      currentTarget: {
        dataset: { keyid },
      },
      target: { value },
    } = event

    currentText.current = JSON.stringify({ key: keyid, value: value.trim() })
  }

  const handleBlur = () => {
    if (!currentText.current) return

    try {
      const { key, value } = JSON.parse(currentText.current)
      dispatch({ type: 'updateValue', payload: { key, value: value.trim() } })
    } catch (e) {
      // console.log('parse error', e)
      // ignoring JSON parse
    } finally {
      currentText.current = ''
    }
  }

  const pasteAsPlainText = (event) => {
    event.preventDefault()

    const text = event.clipboardData.getData('text/plain')
    document.execCommand('insertHTML', false, text.trim())
  }

  const disableNewlines = (event) => {
    const keyCode = event.keyCode || event.which

    if (keyCode === 13) {
      event.returnValue = false
      if (event.preventDefault) event.preventDefault()
      event.target.blur()
    }
  }

  if (!data) return <div className={moduleStyles.loader}>Loading</div>

  const buttonNode = (
    <a
      href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(state.translations))}`}
      download={`${locale}.json`}
    >
      Save all to json file
    </a>
  )

  return (
    <div className={moduleStyles.wrapper}>
      <nav>
        <div>
          <h3>
            Bora Translations for <mark>{locale}</mark> locale
          </h3>
        </div>
        <div className={moduleStyles.upperButton}>{buttonNode}</div>
      </nav>
      {R.keys(data[locale]).map((key) => {
        const actualKey = getActualKey(key)
        return (
          <Row key={key}>
            <Heading>
              <KeyPrefix>{key.replace(actualKey, '')}</KeyPrefix>
              {actualKey}
            </Heading>
            <AroundValue>
              <ContentEditable
                style={{
                  ...(state.translations[key] !== data[locale][key] && { background: '#fa807247' }),
                  direction: locale === 'ar' ? 'rtl' : 'ltr',
                }}
                onKeyPress={disableNewlines}
                onPaste={pasteAsPlainText}
                html={state.translations[key]}
                disabled={false}
                onChange={handleChange}
                onBlur={handleBlur}
                data-keyid={key}
                spellCheck
              />
            </AroundValue>
          </Row>
        )
      })}

      <AroundDownloadButton>{buttonNode}</AroundDownloadButton>
    </div>
  )
}

export default withRouter(Translations)
