import { useEffect } from 'react'

export const RootStyles = ({ locale }) => {
  useEffect(() => {
    const root = document.querySelector(':root')
    root.style.setProperty('--writing-mode', locale === 'ar' ? 'rl' : 'lr')
    root.style.setProperty('--text-direction', locale === 'ar' ? 'rtl' : 'ltr')
    root.style.setProperty('--align', locale === 'ar' ? 'right' : 'left')
    root.style.setProperty('--flex-row-direction', locale === 'ar' ? 'row-reverse' : 'row')
    root.style.setProperty('--icon-rotate', locale === 'ar' ? 'rotateY(180deg)' : 'rotateY(0deg)')
    root.style.setProperty('--locale-justify', locale === 'ar' ? 'flex-end' : 'flex-start')
    root.style.setProperty('--phone-number-label-order', locale === 'ar' ? 3 : 0)
  }, [locale])

  return null
}
