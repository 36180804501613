import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText } from 'bora-material-ui'
import moduleStyles from './RequiredLabel.module.scss'
import cn from 'classnames'

export const RequiredLabel = ({
  wrapperLabelStyles,
  required,
  label,
  text,
  hideLabel,
  labelStyles,
  hideStar,
  starStyles,
  absoluteEndStar = false,
}) => (
  <UILayout zIndex="1" style={{ flexShrink: '0' }} padding="0 0 2px 0" {...wrapperLabelStyles}>
    <UIText
      display-if={!hideLabel}
      size="18px"
      color="#000000"
      weight="bold"
      align="left"
      translate={label}
      text={text}
      {...labelStyles}
    />
    <UIText
      text="*"
      size="18px"
      color="red"
      weight="bold"
      align="left"
      lineHeight="4px"
      whiteSpace="pre"
      display-if={required && !hideStar}
      {...starStyles}
      className={cn({ [moduleStyles.star]: absoluteEndStar })}
    />
  </UILayout>
)

RequiredLabel.propTypes = {
  label: PropTypes.any.isRequired,
  text: PropTypes.string,
  hideLabel: PropTypes.bool.isRequired,
  hideStar: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  invalid: PropTypes.bool.isRequired,
  wrapperLabelStyles: PropTypes.any.isRequired,
  labelStyles: PropTypes.any.isRequired,
  starStyles: PropTypes.any.isRequired,
}

RequiredLabel.defaultProps = {
  text: '',
}
