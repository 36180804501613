import React from 'react'
import PropTypes from 'prop-types'
import { UILayout, UIText, UIButton, UIIcon, UITextField } from 'bora-material-ui'
import { identity, cond, T, complement, compose, multiply, pathOr } from 'ramda'
import { getStyle } from '../utils/liinilaevadThemeUtils'
import { getMaritimeStyle, isMaritime } from '../utils/maritimeStyleUtils'
import { translate } from '../modules/Common/Translator'
import moduleStyles from '../modules/Modal/Modals.module.scss'
import messages from '../consts/messages'

const maritimeStyles = isMaritime
  ? {
      border: '1px solid #D4D4D4',
      borderRadius: '4px',
      boxShadow: 'none',
    }
  : {}

const styles = {
  textField: {
    height: '32px',
    width: '50px',
    margin: '0 15px',
    ...maritimeStyles,
  },
  inputStyle: {
    textAlign: 'center',
    padding: getMaritimeStyle('1px 5px 0', '1px 14px 0'),
  },
}

const getTargetValue = pathOr(0, ['target', 'value'])

const IncDecField = (props) => {
  const { label, onChange, onBlur, activeValue, step, range, unitCoefficient, disabled } = props

  const gtLowerBound = (value) => !range || value > range.MIN.value
  const ltUpperBound = (value) => !range || value < range.MAX.value

  const limitValue = cond([
    [complement(gtLowerBound), () => range.MIN.value],
    [complement(ltUpperBound), () => range.MAX.value],
    [T, identity],
  ])

  const getValueInUnit = multiply(unitCoefficient)

  const getTargetValueAndlimitWithUnit = compose(limitValue, getValueInUnit, getTargetValue)

  return (
    <UILayout
      className={moduleStyles.formDirection}
      margin={getMaritimeStyle('20px 0 0 0', '0')}
      column={!isMaritime}
      width="100%"
      position="relative"
    >
      <UIText
        align={getMaritimeStyle('left', 'center')}
        width={getMaritimeStyle('50%', 'auto')}
        padding={getMaritimeStyle('15px 0 0 0', '0')}
        size={getMaritimeStyle('16px', '18px')}
        color="#000000"
        text={label}
        className={moduleStyles.text}
      />
      {isMaritime ? (
        <UILayout column width="auto" margin="0 48px 0 0">
          <UILayout center j-flex-center margin="10px 0 6px 0" width="auto">
            <UIButton
              type="circle"
              background={getStyle('#7ed321', '#0eb1bc')}
              disabled={disabled || !gtLowerBound(activeValue)}
              click={() => onChange(activeValue - getValueInUnit(step))}
            >
              <UIIcon fill="white" type="remove" />
            </UIButton>
            <UITextField
              type="number"
              value={activeValue / unitCoefficient || ''}
              hintText=""
              onBlur={(e) => {
                const value = getTargetValueAndlimitWithUnit(e)
                onChange(value)
                onBlur(value)
              }}
              change={(val) => onChange(getValueInUnit(val))}
              style={styles.textField}
              inputStyle={styles.inputStyle}
              disabled={disabled}
            />
            <UIButton
              type="circle"
              background={getStyle('#7ed321', '#0eb1bc')}
              disabled={disabled || !ltUpperBound(activeValue)}
              click={() => {
                const value = activeValue + getValueInUnit(step)
                onChange(value)
                onBlur(value)
              }}
            >
              <UIIcon fill="white" type="add" />
            </UIButton>
          </UILayout>
          <UILayout display-if={range} center j-flex-center width="auto">
            <UIText lineHeight="18px" size="14px" color="#79909b">
              {translate(messages.minIncDecLabel)} {range.MIN.title} / {translate(messages.maxIncDecLabel)}{' '}
              {range.MAX.title}
            </UIText>
          </UILayout>
        </UILayout>
      ) : (
        <React.Fragment>
          <UILayout center j-flex-center margin="10px 0 6px 0" width="auto">
            <UIButton
              type="circle"
              background={getStyle('#7ed321', '#0eb1bc')}
              disabled={disabled || !gtLowerBound(activeValue)}
              click={() => onChange(activeValue - getValueInUnit(step))}
            >
              <UIIcon fill="white" type="remove" />
            </UIButton>
            <UITextField
              type="number"
              value={activeValue / unitCoefficient || ''}
              hintText=""
              onBlur={(e) => {
                const value = getTargetValueAndlimitWithUnit(e)
                onChange(value)
                onBlur(value)
              }}
              change={(val) => onChange(getValueInUnit(val))}
              style={styles.textField}
              inputStyle={styles.inputStyle}
              disabled={disabled}
            />
            <UIButton
              type="circle"
              background={getStyle('#7ed321', '#0eb1bc')}
              disabled={disabled || !ltUpperBound(activeValue)}
              click={() => {
                const value = activeValue + getValueInUnit(step)
                onChange(value)
                onBlur(value)
              }}
            >
              <UIIcon fill="white" type="add" />
            </UIButton>
          </UILayout>
          <UILayout display-if={range} center j-flex-center width="auto">
            <UIText lineHeight="18px" size="14px" margin="0 16px" color="#79909b">
              {translate(messages.minIncDecLabel)} {range.MIN.title} / {translate(messages.maxIncDecLabel)}{' '}
              {range.MAX.title}
            </UIText>
          </UILayout>
        </React.Fragment>
      )}
    </UILayout>
  )
}

IncDecField.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  activeValue: PropTypes.number,
  step: PropTypes.number,
  range: PropTypes.shape({
    MIN: PropTypes.object,
    MAX: PropTypes.object,
  }),
  unitCoefficient: PropTypes.number,
  disabled: PropTypes.bool,
}

IncDecField.defaultProps = {
  label: '',
  activeValue: 0,
  step: 1,
  range: undefined,
  unitCoefficient: 1,
  disabled: false,
  onBlur: () => {},
}

export default IncDecField
