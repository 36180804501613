import React from 'react'
import PropTypes from 'prop-types'
import { Field } from 'redux-form'
import { UITextField, UILayout, UIIcon, UIText } from 'bora-material-ui'

import { IconBeforeField, RequiredLabel } from './uiComponents'
import { required as validateRequired } from '../utils/validate'
import messages from '../consts/messages'
import { isLiinilaevad } from '../utils/liinilaevadThemeUtils'
import { isMaritime } from '../utils/maritimeStyleUtils'

export const TextField = (
  {
    input: { value, name, onChange, onBlur, onFocus },
    meta: { touched, error, invalid },
    type,
    required,
    errorStyles,
    underlineShow,
    readOnly,
    ...fieldStyles
  },
  { intl = false }
) => {
  fieldStyles.padding = '42px'

  const textError = touched && error && !error.text && intl.formatMessage({ id: error })
  const reduxError = invalid && required && error.text && intl && intl.formatMessage({ id: error.text })
  const hintText =
    intl &&
    messages[`${name}Placeholder`] &&
    intl.formatMessage(
      messages[`${name}Placeholder${isMaritime && ['phoneNumber', 'phoneCode'].includes(name) ? 'Maritime' : ''}`]
    )
  const errorStyle = { display: 'block', ...errorStyles }

  return (
    <UITextField
      value={value}
      onBlur={onBlur}
      onFocus={onFocus}
      change={onChange}
      hintText={hintText}
      readOnly={readOnly}
      id="i18n.textField"
      type={type || 'text'}
      underlineShow={underlineShow}
      errorText={textError || reduxError}
      errorStyle={errorStyle}
      {...fieldStyles}
    />
  )
}

TextField.propTypes = {
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  required: PropTypes.bool,
  fieldStyles: PropTypes.object,
  underlineShow: PropTypes.bool,
  errorStyles: PropTypes.object,
  readOnly: PropTypes.bool,
}

TextField.defaultProps = {
  required: false,
  underlineShow: false,
  readOnly: false,
  fieldStyles: {},
  errorStyles: {},
}

TextField.contextTypes = {
  intl: PropTypes.any,
}

export const Input = (props) => {
  const {
    icon,
    required,
    hideLabel,
    hideStar,
    showPlus,
    starStyles,
    absoluteEndStar,
    text,
    labelStyles,
    wrapperStyles,
    wrapperLabelStyles,
    wrapperInputStyles,
    locked,
    translateLabel,
    showText,
    substitutionalLabelForLiinilaevad,
    editMode,
    ...textFieldProps
  } = props

  return (
    <UILayout column width="100%" padding="30px 30px 0 0" {...wrapperStyles}>
      <RequiredLabel
        label={translateLabel || messages[props.input.name]}
        text={text}
        hideLabel={hideLabel}
        hideStar={hideStar}
        required={required}
        invalid={props.meta.invalid}
        wrapperLabelStyles={wrapperLabelStyles}
        labelStyles={labelStyles}
        starStyles={starStyles}
        absoluteEndStar={absoluteEndStar}
      />
      <UILayout position="relative" padding="16px 0 0 0" {...wrapperInputStyles} center borderRadius="inherit">
        <IconBeforeField icon={icon} showPlus={showPlus} plusColor={wrapperInputStyles.plusColor} />
        {isLiinilaevad && showText && (
          <UIText
            color="#000000"
            width="145px"
            font="SourceSansPro, Sans-Serif"
            size="16px"
            align="left"
            translate={translateLabel || substitutionalLabelForLiinilaevad || messages[props.input.name]}
          />
        )}
        <TextField
          required={required}
          {...textFieldProps}
          style={{
            ...textFieldProps.style,
            width: substitutionalLabelForLiinilaevad && isLiinilaevad ? (editMode ? '86px' : '100px') : '100%',
          }}
        />
        <UILayout display-if={locked} position="absolute" right="10px" width="auto">
          <UIIcon type="iconLock" />
        </UILayout>
      </UILayout>
    </UILayout>
  )
}

Input.propTypes = {
  meta: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  icon: PropTypes.string,
  text: PropTypes.string,
  required: PropTypes.bool,
  labelStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  wrapperInputStyles: PropTypes.object,
  hideLabel: PropTypes.bool,
  wrapperLabelStyles: PropTypes.object,
  hideStar: PropTypes.bool,
  showPlus: PropTypes.bool,
  starStyles: PropTypes.object,
  locked: PropTypes.bool,
  showText: PropTypes.bool,
  editMode: PropTypes.bool,
  substitutionalLabelForLiinilaevad: PropTypes.string,
}

Input.defaultProps = {
  required: false,
  hideLabel: false,
  text: '',
  icon: '',
  labelStyles: {},
  wrapperStyles: {},
  wrapperInputStyles: {},
  wrapperLabelStyles: {},
  hideStar: false,
  showPlus: false,
  starStyles: {},
  locked: false,
  editMode: false,
  showText: false,
}

const InputField = ({ name, type, label, text, validate = [], required, translateLabel, ...props }) => {
  const fieldProps = { required, text, translateLabel }

  return (
    <Field
      name={name}
      type={type}
      label={label}
      component={Input}
      props={fieldProps}
      validate={required ? [validateRequired, ...validate] : validate}
      {...props}
    />
  )
}

InputField.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.func)]),
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  text: PropTypes.string,
  required: PropTypes.bool,
  translateLabel: PropTypes.object,
}

InputField.defaultProps = {
  label: '',
  text: '',
  placeholder: '',
  required: false,
}

export default InputField
